import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    FaHome, FaBoxOpen, FaTags, FaUsers, FaCog, FaImages, FaFolderOpen,
    FaPowerOff, FaCaretSquareDown, FaMinusSquare, FaBoxes, FaCaretRight
} from 'react-icons/fa';
import Swal from 'sweetalert2';
import './AdminSidebar.css';

const AdminSidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isProductSubmenuOpen, setIsProductSubmenuOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        // Automatically collapse for mobile and tablet
        if (windowWidth <= 768) {
            setIsCollapsed(true);
        }

        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth]);

    const toggleSidebar = () => setIsCollapsed(!isCollapsed);

    const handleLogout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will be logged out from your session!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, logout!',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('adminToken');
                navigate('/cp-login');
                Swal.fire('Logged out!', 'You have been logged out successfully.', 'success');
            }
        });
    };

    const toggleProductSubmenu = () => {
        setIsProductSubmenuOpen(!isProductSubmenuOpen);
    };

    const menuItems = [
        { path: "insights", icon: <FaHome />, label: "Overview" },
        { path: "orders", icon: <FaBoxOpen />, label: "Orders", hasSubmenu: false },
        {
            path: "", icon: <FaBoxes />, label: "Product", hasSubmenu: true, 
            submenu: [
                { path: "product/list", label: "List" },
                { path: "product/create", label: "Create" },
                { path: "product/variation", label: "Variation" },
                { path: "product/attributes", label: "Attributes" },
                { path: "product/category", label: "Categories" }
            ]
        },
        { path: "send-mail", icon: <FaTags />, label: "Send Email" },
        { path: "attributes", icon: <FaCog />, label: "Attributes" },
        { path: "users", icon: <FaUsers />, label: "Users", hasSubmenu: false },
        { path: "media", icon: <FaImages />, label: "Media" },
        { path: "coupons", icon: <FaTags />, label: "Coupons" },
        { path: "setting", icon: <FaCog />, label: "Settings" },
    ];

    return (
        <div className={`admin-sidebar ${isCollapsed ? 'collapsed' : ''}`}>
            <div className="admin-sidebar__logo">
                {!isCollapsed && <h1>Fayozstar</h1>}
                <button className="admin-sidebar__toggle-btn" onClick={toggleSidebar}>
                    {isCollapsed ? <FaFolderOpen /> : <FaMinusSquare />}
                </button>
            </div>
            <ul className="admin-sidebar__menu">
                {menuItems.map((item, index) => (
                    <li key={index} className={`admin-sidebar__menu-item ${location.pathname.includes(item.path) ? 'active' : ''}`}>
                        {!item.hasSubmenu ? (
                            <Link to={item.path}>
                                {item.icon}
                                {!isCollapsed && <span>{item.label}</span>}
                            </Link>
                        ) : (
                            <div onClick={toggleProductSubmenu} className="admin-sidebar__menu-item--dropdown">
                                {item.icon}
                                {!isCollapsed && <span>{item.label}</span>}
                                {!isCollapsed && (
                                    <span className="submenu-arrow">
                                        {isProductSubmenuOpen ? <FaCaretSquareDown /> : <FaCaretRight />}
                                    </span>
                                )}
                            </div>
                        )}
                        {item.hasSubmenu && isProductSubmenuOpen && !isCollapsed && (
                            <ul className="admin-sidebar__submenu">
                                {item.submenu.map((subItem, subIndex) => (
                                    <li key={subIndex} className={`admin-sidebar__submenu-item ${location.pathname.includes(subItem.path) ? 'active' : ''}`}>
                                        <Link to={subItem.path}>
                                            {subItem.label}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
                <li className="admin-sidebar__menu-item logout-item">
                    <button onClick={handleLogout} className="logout-btn">
                        <FaPowerOff />
                        {!isCollapsed && <span>Logout</span>}
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default AdminSidebar;
