// OrderCard.js
import React from 'react';
import './OrderCard.css';

const OrderCard = ({ order, onClick }) => {
    return (
        <div className="order-card" onClick={() => onClick(order)}>
            <h3 className="order-title">Order #{order.id}</h3>
            <p className="order-date">{new Date(order.date).toLocaleDateString()}</p>
            <p className="order-status">{order.status}</p>
            <p className="order-total">Total: ${order.total}</p>
        </div>
    );
};

export default OrderCard;
