import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './NotFound.css'; 

const NotFound = () => {
  return (
    <motion.div
      className="not-found-container"
      initial={{ opacity: 0, y: 50 }}       // Initial: Transparent and slightly below
      animate={{ opacity: 1, y: 0 }}        // Animate: Fade in and rise to original position
      exit={{ opacity: 0, y: 50 }}          // On exit: Fade out and drop
      transition={{ duration: 0.6, ease: "easeOut" }} // Smooth, quicker animation
    >
      <h1>404</h1>
      <p>Oops! The page you're looking for doesn't exist.</p>
      <Link to="/">Go back to Home</Link>
    </motion.div>
  );
};

export default NotFound;
