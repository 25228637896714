import React, { useState, useEffect } from 'react';
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaInstagram, FaEnvelope, FaMapMarkerAlt, FaPhoneAlt, FaClock } from 'react-icons/fa';
import './ContactUs.css';

const ContactUs = () => {
      // Set the page title
  useEffect(() => {
    document.title = `Contact Us | Fayozstar Naturals`; // Change the title as needed
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data Submitted:', formData);
  };

  return (
    <div className="contact-us-page">
      {/* Full-width header with modern look */}
      <div className="breadcrumb-image">
        <div className="breadcrumb-overlay">
          <h1>Contact Us</h1>
        </div>
      </div>

      <div className="contact-us-container">
        <h1 className="heading">
          <span className="underlined-text">Connect</span> with Our Team
        </h1>
        <p className="description-text">
          We’re here to assist you with any inquiries. Reach out and we’ll get back to you shortly.
        </p>
        
        <div className="contact-content">
          {/* Contact Form */}
          <div className="contact-form-container">
            <h2>Get in Touch</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleChange}
                required
              />
              <textarea
                name="message"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              <button type="submit" className="submit-button">Send Message</button>
            </form>
          </div>

          <div className="contact-details-container">
            <h2>Contact Details</h2>
            <p>We'd love to hear from you. Here are ways to reach us:</p>

            <div className="contact-info-grid">
              <div className="contact-info-item">
                <span className="contact-icon"><FaMapMarkerAlt /></span>
                <div>
                  <h4><strong>Address</strong></h4>
                  <p>J. Raya Kuta No. 121</p>
                </div>
              </div>

              <div className="contact-info-item">
                <span className="contact-icon"><FaPhoneAlt /></span>
                <div>
                  <h4><strong>Mobile</strong></h4>
                  <p>(+021) 789 345</p>
                </div>
              </div>

              <div className="contact-info-item">
                <span className="contact-icon"><FaClock /></span>
                <div>
                  <h4><strong>Working Time</strong></h4>
                  <p>Daily 09 am - 05 pm</p>
                </div>
              </div>

              <div className="contact-info-item">
                <span className="contact-icon"><FaEnvelope /></span>
                <div>
                  <h4><strong>Email Address</strong></h4>
                  <p>admin@support.com</p>
                </div>
              </div>
            </div>

            <div className="social-media">
              <p>Follow us on:</p>
              <FaFacebookF className="social-icon" />
              <FaTwitter className="social-icon" />
              <FaLinkedinIn className="social-icon" />
              <FaInstagram className="social-icon" />
            </div>
          </div>
        </div>
        
        {/* Full-Width Google Map */}
        <div className="map-container">
        <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23592.262869743732!2d4.521433723533928!3d8.538886797883107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8782dd29bb23075f%3A0x9370478e36e8b049!2sFAYOZSTAR%20NATURALS!5e0!3m2!1sen!2sng!4v1728791216222!5m2!1sen!2sng"
        width="100%"
        height="400"
        allowFullScreen=""
        loading="lazy"
        style={{ border: 0 }}
        title="Google Map showing Fayozstar Naturals location" // Add title here
      ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
