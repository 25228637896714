import React, { useState } from 'react';
import styles from './Attributes.css';

const AttributesDashboard = () => {
  const [isEditing,] = useState(false);

  return (
    <div className={styles.attributesContainer}>
      {/* Header */}
      <header className={styles.attributesHeader}>
        <h1>Manage Attributes</h1>
        <div className={styles.actions}>
          <input type="text" placeholder="Search attributes..." className={styles.searchInput} />
          <button className={styles.filterBtn}>Filter</button>
        </div>
      </header>

      <div className={styles.content}>
        {/* Left Pane: Attribute List */}
        <div className={styles.attributeList}>
          <div className={styles.attributeCard}>
            <h2>Color</h2>
            <span className={`${styles.badge} ${styles.dropdownBadge}`}>Dropdown</span>
            <span className={`${styles.status} ${styles.active}`}>Active</span>
            <div className={styles.cardActions}>
              <button className={styles.editBtn}>Edit</button>
              <button className={styles.deleteBtn}>Delete</button>
            </div>
          </div>

          <button className={styles.addNewAttribute}>+ Add New Attribute</button>
        </div>

        {/* Right Pane: Add/Edit Form */}
        <div className={styles.attributeForm}>
          <h2>{isEditing ? "Edit Attribute" : "Add New Attribute"}</h2>
          <form>
            <input type="text" placeholder="Attribute Name" className={styles.inputField} />
            <select className={styles.selectField}>
              <option value="text">Text</option>
              <option value="dropdown">Dropdown</option>
              {/* More types... */}
            </select>

            <input type="text" placeholder="Enter options..." className={styles.inputField} />
            
            <div className={styles.formActions}>
              <button className={styles.saveBtn}>Save</button>
              <button className={styles.cancelBtn}>Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AttributesDashboard;
