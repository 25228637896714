import React, { useState } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './SendEmail.module.css';
import { FaPaperPlane } from 'react-icons/fa';

const AdminEmailForm = () => {
  const [to, setTo] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!to || !subject || !content) {
      return setStatus('All fields are required.');
    }
    setLoading(true);
    setStatus('');

    const formData = new FormData();
    formData.append('to', to);
    formData.append('subject', subject);
    formData.append('content', content);
    if (attachment) {
      formData.append('attachment', attachment);
    }

    try {
      const response = await axios.post(`${apiUrl}/api/send-email`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setStatus(response.data.message);
      setTo('');
      setSubject('');
      setContent('');
      setAttachment(null);
    } catch (error) {
      setStatus('Failed to send email. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const toolbarOptions = [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['link', 'image'],
    [{ 'align': [] }],
    ['clean'],
  ];

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.title}>Send Email</h2>
      <form onSubmit={handleSubmit} className={styles.form} encType="multipart/form-data">
        <input
          type="email"
          placeholder="Recipient's Email"
          value={to}
          onChange={(e) => { setTo(e.target.value); setStatus(''); }}
          required
          className={styles.input}
        />
        <input
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => { setSubject(e.target.value); setStatus(''); }}
          required
          className={styles.input}
        />
        <ReactQuill
          theme="snow"
          value={content}
          onChange={(val) => { setContent(val); setStatus(''); }}
          placeholder="Message Content"
          modules={{ toolbar: toolbarOptions }}
          className={styles.textarea}
        />
        <input
          type="file"
          onChange={(e) => setAttachment(e.target.files[0])}
          className={styles.fileInput}
        />
        <button type="submit" className={styles.button} disabled={loading}>
          {loading ? 'Sending...' : 'Send Email'}
          <FaPaperPlane />
        </button>
      </form>
      {status && <p className={styles.status}>{status}</p>}
    </div>
  );
};

export default AdminEmailForm;
