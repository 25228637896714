import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for navigation
import { GoogleLogin } from '@react-oauth/google';
import './Login.css';
import Logo from '../../assets/images/Logo.png';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaEye } from 'react-icons/fa';

const apiUrl = process.env.REACT_APP_API_URL;

const Login = () => {
  const navigate = useNavigate();  // Initialize navigate
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState(() => {
    const savedUserData = localStorage.getItem('userData');
    return savedUserData ? JSON.parse(savedUserData) : null;
  });
 
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}/api/login`, { email, password });
      const token = response.data.token;

      localStorage.setItem('token', token);

      await fetchUserData(token);

      Swal.fire({
        title: 'Login Successful',
        text: 'Redirecting to your dashboard...',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/dashboard');  // Redirect to dashboard
      });
    } catch (err) {
      let errorMessage = 'Please check your email or password and try again.';
      if (!err.response) errorMessage = 'Network error. Please check your connection and try again.';

      Swal.fire({
        title: 'Login Failed',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'Retry',
      });
    }
  };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      const response = await axios.post(`${apiUrl}/api/auth/google`, {
        token: credentialResponse.credential,
      });

      const token = response.data.token;

      localStorage.setItem('token', token);

      await fetchUserData(token);

      Swal.fire({
        title: 'Login Successful',
        text: 'Redirecting to your dashboard...',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/dashboard');  // Redirect to dashboard
      });
    } catch (error) {
      Swal.fire({
        title: 'Login Failed',
        text: 'Google login failed. Please try again.',
        icon: 'error',
        confirmButtonText: 'Retry',
      });
    }
  };

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get(`${apiUrl}/api/user/details`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const user = response.data;
      setUserData(user);

      localStorage.setItem('userData', JSON.stringify(user));
    } catch (err) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch user details. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserData(token).then(() => {
        // Check if user data exists, then redirect to dashboard
        if (userData) {
          navigate('/dashboard');
        }
      });
    }
  }, [navigate, userData]);  // Depend on navigate and userData

  return (
    <div className="login-page">
      <div className="login-wrapper">
        <div className="login-box">
          <img src={Logo} alt="Logo" className="login-logo" />
          
          <h1 className="login-heading">
            {userData?.firstName ? `Welcome back, ${userData.firstName}` : 'Welcome back!'}
          </h1>
          
          <p className="login-subheading">Please enter your login details below.</p>

          <form className="login-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="form-group password-field">
              <label htmlFor="password">Password</label>
              <div className="password-wrapper">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span
                  className="toggle-password"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? '🙈' : <FaEye />}
                </span>
              </div>
            </div>
            <div className="forgot-password">
              <a href="/forgot-password">Forgot password?</a>
            </div>

            <button type="submit" className="login-button">Login</button>

            <div className="divider"></div>

            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={() => {
                Swal.fire({
                  title: 'Google Login Failed',
                  text: 'Unable to login with Google. Please try again.',
                  icon: 'error',
                  confirmButtonText: 'OK',
                });
              }}
            />
          </form>
          <p className="signup-text">
            Don't have an account? <a href="/register">Sign up for free</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
