import React, { useState } from 'react';
import './ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const response = await fetch(`${apiUrl}/api/auth/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (data.success) {
        setMessage('Password reset link has been sent to your email.');
      } else {
        setMessage('Error: ' + data.message);
      }
    } catch (error) {
      setMessage('Error: Could not send reset link.');
    }
    setLoading(false);
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-box">
        <h2 className="forgot-password-title">Forgot Password</h2>
        <p className="forgot-password-description">
          Please enter the email associated with your account, and we will send you a link to reset your password.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="forgot-password-input-group">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="forgot-password-email-input"
            />
          </div>
          <button type="submit" className="forgot-password-submit-button" disabled={loading}>
            {loading ? 'Sending...' : 'Submit'}
          </button>
        </form>
        {message && (
          <p className={`forgot-password-message ${message.includes('Error') ? 'error' : 'success'}`}>
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
