import React from 'react';
import '../../components/MobileFooter/mobile-footer.css';
import logo from '../../assets/images/Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';

const MobileFooter = () => {
  return (
    <footer className="mobile-footer">
      <div className="footer-top">
        <img src={logo} alt="Logo" className="footer-logo" />
        <div className="footer-contact">
          <p><FontAwesomeIcon icon={faEnvelope} /> support@fayozstar.com</p>
          <p><FontAwesomeIcon icon={faPhone} /> (+234) 808 – 581 – 2453</p>
          <p><FontAwesomeIcon icon={faMapMarkerAlt} /> 146 Ogudu Road, Ogudu, Lagos</p>
          <p>Mon – Fri / 9:00 AM – 5:00 PM | Sat: 9am – 3pm</p>
        </div>
      </div>

      <div className="footer-newsletter">
        <h3>Newsletter</h3>
        <p>Don’t miss out <strong>on thousands of great deals & promotions</strong>.</p>
        <form className="newsletter-form">
          <input type="email" placeholder="Enter Email Address" />
          <button type="submit">Subscribe</button>
        </form>
      </div>

      <div className="footer-links">
        <div className="quick-links">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="/">About Us</a></li>
            <li><a href="/">Contact Us</a></li>
            <li><a href="/">Shipping Information</a></li>
            <li><a href="/">My Account</a></li>
            <li><a href="/">Order Tracking</a></li>
          </ul>
        </div>
        <div className="legal-links">
          <h4>Legal</h4>
          <ul>
            <li><a href="/">Privacy</a></li>
            <li><a href="/">Terms</a></li>
            <li><a href="/">Refunds</a></li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="social-icons">
          <a href="/"><FontAwesomeIcon icon={faInstagram} /></a>
          <a href="/"><FontAwesomeIcon icon={faTwitter} /></a>
          <a href="/"><FontAwesomeIcon icon={faFacebook} /></a>
        </div>
        <p>&copy; 2024 BeautybyDaz. All rights reserved | Designed by Bave</p>
      </div>
    </footer>
  );
};

export default MobileFooter;
