// CreateProduct.js
import React, { useState } from 'react';
import './CreateProduct.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faImage, faTag, faDollarSign, faWarehouse, faLink, faTruck, faInfoCircle, faCog } from '@fortawesome/free-solid-svg-icons';

const CreateProduct = () => {
  const [product, setProduct] = useState({
    name: '', description: '', sku: '', visibility: 'Visible',
    price: '', salePrice: '', taxClass: 'Taxable',
    stockStatus: 'In Stock', manageStock: false, stockQuantity: '',
    weight: '', length: '', width: '', height: '', shippingClass: '',
    attributes: [], variations: [], upSells: '', crossSells: '',
    purchaseNote: '', menuOrder: '', metaTitle: '', metaDescription: ''
  });
  const [file, setFile] = useState(null);

  const handleChange = (e) => setProduct({ ...product, [e.target.name]: e.target.value });
  const handleFileChange = (e) => setFile(e.target.files[0]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(product).forEach(key => formData.append(key, product[key]));
    if (file) formData.append('file', file);
    try {
      const response = await fetch(`/api/products/upload`, {
        method: 'POST', body: formData
      });
      const result = await response.json();
      alert(response.ok ? 'Product uploaded successfully' : result.error || 'Failed to upload product');
    } catch (error) {
      console.error('Error uploading product:', error);
      alert('Error uploading product');
    }
  };

  return (
    <div className="create-product-container">
      <header className="product-header">
        <h1>Create New Product</h1>
        <button className="btn btn-save-header">
          <FontAwesomeIcon icon={faSave} /> Publish
        </button>
      </header>
      <form className="create-product-form" onSubmit={handleSubmit}>
        <div className="form-section-left">
          <ProductInformation product={product} onChange={handleChange} />
          <PricingSection product={product} onChange={handleChange} />
          <InventorySection product={product} onChange={handleChange} />
          <ShippingSection product={product} onChange={handleChange} />
          <LinkedProductsSection product={product} onChange={handleChange} />
          <AttributesSection product={product} onChange={handleChange} />
          <AdvancedSection product={product} onChange={handleChange} />
        </div>
        <div className="form-section-right">
          <MediaSection onFileChange={handleFileChange} />
          <button type="submit" className="btn btn-save">
            <FontAwesomeIcon icon={faSave} /> Save Product
          </button>
        </div>
      </form>
    </div>
  );
};

// Component Sections

const ProductInformation = ({ product, onChange }) => (
  <div className="form-section">
    <h2><FontAwesomeIcon icon={faInfoCircle} /> Product Information</h2>
    <label>Name</label>
    <input type="text" name="name" value={product.name} onChange={onChange} required />
    <label>Description</label>
    <textarea name="description" value={product.description} onChange={onChange} required />
    <label>SKU</label>
    <input type="text" name="sku" value={product.sku} onChange={onChange} />
    <label>Visibility</label>
    <select name="visibility" value={product.visibility} onChange={onChange}>
      <option value="Visible">Visible</option>
      <option value="Hidden">Hidden</option>
    </select>
  </div>
);

const PricingSection = ({ product, onChange }) => (
  <div className="form-section">
    <h2><FontAwesomeIcon icon={faDollarSign} /> Pricing</h2>
    <label>Regular Price</label>
    <input type="number" name="price" value={product.price} onChange={onChange} required />
    <label>Sale Price</label>
    <input type="number" name="salePrice" value={product.salePrice} onChange={onChange} />
    <label>Tax Class</label>
    <select name="taxClass" value={product.taxClass} onChange={onChange}>
      <option value="Taxable">Taxable</option>
      <option value="Non-taxable">Non-taxable</option>
    </select>
  </div>
);

const InventorySection = ({ product, onChange }) => (
  <div className="form-section">
    <h2><FontAwesomeIcon icon={faWarehouse} /> Inventory</h2>
    <label>Stock Status</label>
    <select name="stockStatus" value={product.stockStatus} onChange={onChange}>
      <option value="In Stock">In Stock</option>
      <option value="Out of Stock">Out of Stock</option>
    </select>
    <label>Manage Stock</label>
    <input type="checkbox" name="manageStock" checked={product.manageStock} onChange={(e) => onChange({ target: { name: 'manageStock', value: e.target.checked } })} />
    <label>Stock Quantity</label>
    <input type="number" name="stockQuantity" value={product.stockQuantity} onChange={onChange} />
  </div>
);

const ShippingSection = ({ product, onChange }) => (
  <div className="form-section">
    <h2><FontAwesomeIcon icon={faTruck} /> Shipping</h2>
    <label>Weight (kg)</label>
    <input type="number" name="weight" value={product.weight} onChange={onChange} />
    <label>Dimensions (cm)</label>
    <div className="dimension-inputs">
      <input type="number" name="length" placeholder="Length" value={product.length} onChange={onChange} />
      <input type="number" name="width" placeholder="Width" value={product.width} onChange={onChange} />
      <input type="number" name="height" placeholder="Height" value={product.height} onChange={onChange} />
    </div>
    <label>Shipping Class</label>
    <input type="text" name="shippingClass" value={product.shippingClass} onChange={onChange} />
  </div>
);

const LinkedProductsSection = ({ product, onChange }) => (
  <div className="form-section">
    <h2><FontAwesomeIcon icon={faLink} /> Linked Products</h2>
    <label>Up-sells</label>
    <input type="text" name="upSells" value={product.upSells} onChange={onChange} />
    <label>Cross-sells</label>
    <input type="text" name="crossSells" value={product.crossSells} onChange={onChange} />
  </div>
);

const AttributesSection = ({ product, onChange }) => (
  <div className="form-section">
    <h2><FontAwesomeIcon icon={faTag} /> Attributes</h2>
    <input type="text" name="attributes" value={product.attributes} onChange={onChange} />
    <button type="button" className="btn btn-primary"><FontAwesomeIcon icon={faPlus} /> Add Attribute</button>
  </div>
);

const AdvancedSection = ({ product, onChange }) => (
  <div className="form-section">
    <h2><FontAwesomeIcon icon={faCog} /> Advanced</h2>
    <label>Purchase Note</label>
    <textarea name="purchaseNote" value={product.purchaseNote} onChange={onChange} />
    <label>Menu Order</label>
    <input type="number" name="menuOrder" value={product.menuOrder} onChange={onChange} />
  </div>
);

// In the MediaSection component, add image preview feature
const MediaSection = ({ onFileChange }) => {
  const [preview, setPreview] = useState(null);

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    onFileChange(e);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="form-section">
      <h2><FontAwesomeIcon icon={faImage} /> Media</h2>
      {preview && <img src={preview} alt="Preview" className="image-preview" />}
      <input type="file" onChange={handleFileSelect} />
    </div>
  );
};


export default CreateProduct;
