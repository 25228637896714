import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const isAuthenticated = !!localStorage.getItem('adminToken');

    if (!isAuthenticated) {
        return <Navigate to="/cp-login" replace />; 
    }

    return children; // Render children (i.e., protected component) if authenticated
};

export default ProtectedRoute;
