import React, { useState, useEffect } from "react";
import axios from 'axios';
import Swal from 'sweetalert2';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./FileManager.css";

const FileManager = () => {
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [imageUrl, setImageUrl] = useState(''); // State for image URL
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (apiUrl) {
      setLoading(true);
      axios.get(`${apiUrl}/api/admin/files`)
        .then((response) => {
          setFiles(response.data.files);
          setLoading(false);
        })
        .catch((error) => {
          Swal.fire('Error', 'Failed to fetch files', 'error');
          setLoading(false);
        });
    }
  }, [apiUrl]);

  const handleFileUpload = (event) => {
    const uploadedFiles = event.target.files;
    if (uploadedFiles.length === 0) return;

    const formData = new FormData();
    for (let file of uploadedFiles) {
      formData.append('files', file);
    }

    setUploading(true);
    axios.post(`${apiUrl}/api/admin/file/upload`, formData)
      .then((response) => {
        setFiles((prevFiles) => [...prevFiles, ...response.data.files]);
        setUploading(false);
        Swal.fire('Success', 'Files uploaded successfully!', 'success');
      })
      .catch((error) => {
        Swal.fire('Error', 'Failed to upload files', 'error');
        setUploading(false);
      });
  };

  const handleFileSelect = (fileId) => {
    setSelectedFiles((prevSelected) =>
      prevSelected.includes(fileId)
        ? prevSelected.filter((id) => id !== fileId)
        : [...prevSelected, fileId]
    );
  };

  const handleDelete = () => {
    if (selectedFiles.length === 0) return;

    Swal.fire({
      title: 'Are you sure?',
      text: `You are about to delete ${selectedFiles.length} file(s).`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete them!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        selectedFiles.forEach((fileId) => {
          axios.delete(`${apiUrl}/api/admin/file/${fileId}`)
            .then(() => {
              setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
              setSelectedFiles((prevSelected) => prevSelected.filter(id => id !== fileId));
              Swal.fire('Deleted!', 'The file(s) have been deleted.', 'success');
            })
            .catch((error) => {
              Swal.fire('Error', 'Failed to delete file', 'error');
            });
        });
      }
    });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    handleFileUpload({ target: { files: droppedFiles } });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedFiles([]);
    } else {
      setSelectedFiles(files.map((file) => file.id));
    }
    setSelectAll(!selectAll);
  };

  // Handle URL Upload
  const handleUrlUpload = () => {
    if (!imageUrl) return;

    setUploading(true);
    axios.post(`${apiUrl}/api/admin/file/upload-url`, { url: imageUrl })
      .then((response) => {
        setFiles((prevFiles) => [...prevFiles, ...response.data.files]);
        setUploading(false);
        Swal.fire('Success', 'Image imported from URL!', 'success');
        setImageUrl('');  // Clear the input
      })
      .catch((error) => {
        Swal.fire('Error', 'Failed to import image from URL', 'error');
        setUploading(false);
      });
  };

  const indexOfLastFile = currentPage * pageSize;
  const indexOfFirstFile = indexOfLastFile - pageSize;
  const currentFiles = files.slice(indexOfFirstFile, indexOfLastFile);
  const totalPages = Math.ceil(files.length / pageSize);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value));
    setCurrentPage(1);
  };

  return (
    <div className="file-manager container mt-4">
      <h2 className="media-manager-title">Media Manager</h2>
      <div className="upload-area mb-4" onDrop={handleDrop} onDragOver={(e) => e.preventDefault()} onClick={() => document.getElementById("fileInput").click()}>
        <i className="bi bi-cloud-arrow-up-fill display-4 text-primary"></i>
        <p className="mt-3">Drop files here or click to upload</p>
        <input type="file" id="fileInput" multiple className="d-none" onChange={handleFileUpload} />
      </div>

      <div className="input-group mb-4">
        <input
          type="text"
          className="form-control"
          placeholder="Enter image URL"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleUrlUpload}>
          Import from URL
        </button>
      </div>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <select className="form-select" value={pageSize} onChange={handlePageSizeChange}>
            <option value="10">Show 10 entries</option>
            <option value="20">Show 20 entries</option>
            <option value="50">Show 50 entries</option>
            <option value="100">Show 100 entries</option>
          </select>
        </div>
        <button className="btn btn-danger" disabled={selectedFiles.length === 0 || uploading} onClick={handleDelete}>
          <i className="fas fa-trash-alt"></i> Delete
        </button>
      </div>

      {loading ? (
        <div className="loading-spinner">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {files.length === 0 ? (
            <div className="text-center">No files available</div>
          ) : (
            <table className="table table-hover table-bordered">
              <thead className="table-light">
                <tr>
                  <th scope="col">
                    <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                  </th>
                  <th scope="col">ID</th>
                  <th scope="col">Thumbnail</th>
                  <th scope="col">Filename</th>
                  <th scope="col">Created</th>
                </tr>
              </thead>
              <tbody>
                {currentFiles.map((file, index) => (
                  <tr key={file.id}>
                    <td>
                      <input type="checkbox" checked={selectedFiles.includes(file.id)} onChange={() => handleFileSelect(file.id)} />
                    </td>
                    <td>{indexOfFirstFile + index + 1}</td>
                    <td>
                      <img src={`${apiUrl}${file.path}?t=${new Date().getTime()}`} alt={file.name} className="thumbnail" />
                    </td>
                    <td>{file.name}</td>
                    <td>{file.createdAt}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {totalPages > 1 && (
            <nav>
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button className="page-link prev" onClick={() => handlePageChange(currentPage - 1)}>
                    <i className="bi bi-chevron-left"></i> Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((_, i) => (
                  <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i + 1)}>
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                  <button className="page-link next" onClick={() => handlePageChange(currentPage + 1)}>
                    Next <i className="bi bi-chevron-right"></i>
                  </button>
                </li>
              </ul>
            </nav>
          )}
        </>
      )}
    </div>
  );
};

export default FileManager;
