import React from 'react';
import './footer.css'; // Import the refined CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__top">
        <div className="footer__logo">
          <h2>Fayozstar</h2>
        </div>
        <div className="footer__icons">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} className="footer__icon" />
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} className="footer__icon" />
        </a>
        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} className="footer__icon" />
        </a>
        </div>
      </div>

      <hr className="footer__divider" />
      <div className="footer__content">
        <div className="footer__section">
          <h4>Quick Links</h4>
          <ul>
            <li>Home</li>
            <li>About</li>
            <li>Pricing</li>
            <li>Pro Version</li>
          </ul>
        </div>
        <div className="footer__section">
          <h4>Products</h4>
          <ul>
            <li>Figma UI System</li>
            <li>Icons Assets</li>
            <li>Responsive Blocks</li>
            <li>Components Library</li>
          </ul>
        </div>
        <div className="footer__section">
          <h4>Resources</h4>
          <ul>
            <li>FAQs</li>
            <li>Quick Start</li>
            <li>Documentation</li>
            <li>User Guide</li>
          </ul>
        </div>
        <div className="footer__section newsletter">
          <h4>Newsletter</h4>
          <form>
            <input type="email" placeholder="mail@example.com" required />
            <div className="checkbox-container">
              <input type="checkbox" id="terms" required />
              <label htmlFor="terms">
                I agree with <span>Privacy Policy</span> and <span>Terms of Condition</span>
              </label>
            </div>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>

      <div className="footer__bottom">
        <p>&copy; {new Date().getFullYear()} Fayozstar Naturals. All rights reserved.</p>
        <div className="footer__chat">
          <p>Have a question? <a href="contact-us">Talk to us</a></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
