import React, { useEffect, useState } from 'react';
import OrderCard from './OrderCard'; // Import your OrderCard component

const MyOrders = () => {
    const [orders, setOrders] = useState([]); // Initialize state

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch('/api/orders'); // Adjust your API endpoint
                const data = await response.json();
                setOrders(data); // Assuming data is an array of orders
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };

        fetchOrders();
    }, []);

    return (
        <div>
            <h2>My Orders</h2>
            {orders && orders.length > 0 ? (
                orders.map((order) => (
                    <OrderCard key={order.id} order={order} />
                ))
            ) : (
                <p>No orders found.</p>
            )}
        </div>
    );
};

export default MyOrders;
