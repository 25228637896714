import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './Sidebar.css';
import { FaChartLine, FaDownload, FaShoppingCart, FaAddressBook, FaFileAlt, FaCog, FaPowerOff } from 'react-icons/fa';
import Logo from '../../assets/images/Logo.png';

function Sidebar({ collapsed }) {
  const navigate = useNavigate();

  // Logout function with confirmation
  const logout = useCallback(() => {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      text: 'You will be logged out from your session.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'No, stay here',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('token');
        navigate('/login');
        Swal.fire('Logged Out!', 'You have been logged out successfully.', 'success');
      }
    });
  }, [navigate]);

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : 'expanded'}`}>
      <img src={Logo} alt="Logo" className="logo" />
      
      <ul>
        <li>
          <Link to="overview">
            <FaChartLine className="icon" />
            {!collapsed && <span>Overview</span>}
          </Link>
        </li>
        <li>
          <Link to="downloads">
            <FaDownload className="icon" />
            {!collapsed && <span>My Downloads</span>}
          </Link>
        </li>
        <li>
          <Link to="orders">
            <FaShoppingCart className="icon" />
            {!collapsed && <span>My Orders</span>}
          </Link>
        </li>
        <li>
          <Link to="myaddress">
            <FaAddressBook className="icon" />
            {!collapsed && <span>My Addresses</span>}
          </Link>
        </li>
        <li>
          <Link to="reports">
            <FaFileAlt className="icon" />
            {!collapsed && <span>Reports</span>}
          </Link>
        </li>
        <li>
          <Link to="settings">
            <FaCog className="icon" />
            {!collapsed && <span>Settings</span>}
          </Link>
        </li>
      </ul>
      <div className="logout" onClick={logout}>
        <FaPowerOff className="icon" />
        {!collapsed && <span>Logout</span>}
      </div>
    </div>
  );
}

export default Sidebar;
