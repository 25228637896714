import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google'; // Import GoogleOAuthProvider
import Header from './components/Header/header';
import MobileHeader from './components/MobileHeader/mobile-header';
import MobileFooter from './components/MobileFooter/mobile-footer';
import Footer from './components/Footer/footer';
import Hero from './components/Hero/hero';
import Preloader from './components/Preloader/preloader';
import ReviewComponent from './components/GoogleReviews/ReviewComponent';
import ContactUs from './components/Contact/ContactUs';
import Dashboard from './components/Dashboard/Dashboard';
import Overview from './components/Dashboard/Overview';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Settings from './components/Dashboard/Settings';
import MyOrders from './components/Dashboard/MyOrders.js';
import TransactionHistory from './components/Dashboard/TransactionHistory.js';
import CategoryPage from './components/Admin/Category.js';
import ProductPage from './components/Product/ProductPage.js';
import AdminDashboard from './components/Admin/cp';
import AdminLogin from './components/Admin/AdminLogin';
import Users from './components/Admin/User';
import CreateProduct from './components/Admin/CreateProduct.js';
import FileManager from './components/Admin/FileManager.js';
import AttributesDashboard from './components/Admin/Attributes.js';
import AdminEmailForm from './components/Admin/SendEmail.js';
import NotFound from './components/NotFound/NotFound.js';
import ForgotPassword from './components/Forgot Password/ForgotPassword.js';
import ResetPassword from './components/Forgot Password/ResetPassword.js';
import './App.css';

function App() {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const isDashboardPath = location.pathname.startsWith('/dashboard');
  const isAdminDashboardPath = location.pathname.startsWith('/cp');
  const isAuthPath = location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/forgot-password' ;

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="App">
          {/* Hide Header and Footer on dashboard, login, or register pages */}
          {!isDashboardPath && !isAuthPath && !isAdminDashboardPath && (
            isMobile ? <MobileHeader /> : <Header />
          )}
          <Routes>
            <Route path="/" element={
              <>
                <Hero />
                <ReviewComponent />
              </>
            } />
            <Route path="*" element={<NotFound />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/shop" element={<ProductPage />} />
            {/* Admin Dashboard Route (with nested routes) */}
            <Route path="/cp/*" element={<AdminDashboard />}>
              <Route path="users" element={<Users />} />
              <Route path="create" element={<CreateProduct />} />
              <Route path="media" element={<FileManager />} />
              <Route path="category" element={<CategoryPage />} />
              <Route path="send-mail" element={<AdminEmailForm />} />
              <Route path="attributes" element={<AttributesDashboard />} />
            </Route>
            {/* Dashboard Route (with nested routes) */}
            <Route path="/dashboard/*" element={<Dashboard />}>
              <Route path="overview" element={<Overview />} />
              <Route path="settings" element={<Settings />} />
              <Route path="orders" element={<MyOrders />} />
              <Route path="reports" element={<TransactionHistory />} />
            </Route>
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/login" element={<Login />} />
            <Route path="/cp-login" element={<AdminLogin />} />
          </Routes>
          {/* Hide Footer on dashboard, login, or register pages */}
          {!isDashboardPath && !isAuthPath && !isAdminDashboardPath && (
            isMobile ? <MobileFooter /> : <Footer />
          )}
        </div>
      )}
    </>
  );
}

// Wrap App in Router and GoogleOAuthProvider
const AppWithRouter = () => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Router>
        <App />
      </Router>
    </GoogleOAuthProvider>
  );
};

export default AppWithRouter;
