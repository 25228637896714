import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import './hero.css';

const images = [
  require('../../assets/Hero/hero1.jpg'),
  require('../../assets/Hero/hero2.jpg'),
  require('../../assets/Hero/hero3.jpg')
];

const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const goToNextImage = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      setIsAnimating(false);
    }, 1000); // Adjust the timeout to match the animation duration
  };

  const goToPreviousImage = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
      setIsAnimating(false);
    }, 1000);
  };

  // Set up auto-change every 10 seconds
  useEffect(() => {
    const interval = setInterval(goToNextImage, 10000);
    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  // Handle swipe gestures
  const handlers = useSwipeable({
    onSwipedLeft: goToNextImage,
    onSwipedRight: goToPreviousImage,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Allow mouse swipes too
  });

  return (
    <div className="hero-container" {...handlers}>
      <div
        className={`hero-image ${isAnimating ? 'animating' : ''}`}
        style={{
          backgroundImage: `url(${images[currentImageIndex]})`,
        }}
      />
      {/* Uncomment if you want to display hero content */}
      {/* <div className="hero-content">
        <h1 className="hero-title">Local Product Of</h1>
        <h2 className="hero-subtitle">Highest Quality</h2>
        <p className="hero-description">Explore the best natural products directly from local farmers.</p>
        <button className="hero-btn">Explore More</button>
      </div> */}
      <button className="hero-nav hero-prev" onClick={goToPreviousImage}>
        &#10094;
      </button>
      <button className="hero-nav hero-next" onClick={goToNextImage}>
        &#10095;
      </button>
    </div>
  );
};

export default Hero;
