import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from './Settings.module.css';

const apiUrl = process.env.REACT_APP_API_URL;

const SettingsComponent = () => {
  const [selectedSection, setSelectedSection] = useState("profile");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [newAdmin, setNewAdmin] = useState({ firstName: "", lastName: "", email: "", password: "" });

  useEffect(() => {
    const fetchAdminDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const response = await axios.get(`${apiUrl}/api/admin/details`, config);
        const data = response.data;

        setFirstName(data.first_name);
        setLastName(data.last_name);
        setEmail(data.email);
      } catch (error) {
        console.error("Error fetching profile data:", error);
        Swal.fire("Error", "Could not fetch profile data.", "error");
      }
    };
    fetchAdminDetails();
  }, []);

  const handleSaveProfile = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const body = { first_name: firstName, last_name: lastName, email, password };
      await axios.put(`${apiUrl}/api/admin/update`, body, config);
      Swal.fire("Success", "Profile updated successfully.", "success");
    } catch (error) {
      Swal.fire("Error", "Profile update failed.", "error");
    } finally {
      setIsSaving(false);
    }
  };

  const handleAddNewAdmin = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const body = { first_name: newAdmin.firstName, last_name: newAdmin.lastName, email: newAdmin.email, password: newAdmin.password };
      await axios.post(`${apiUrl}/api/admin/add`, body, config);
      Swal.fire("Success", "New admin added successfully.", "success");
    } catch (error) {
      Swal.fire("Error", "Failed to add new admin.", "error");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="container">
      <div className={styles.accordion}>
        <div className={styles.accordionItem}>
          <div
            className={selectedSection === "profile" ? styles.activeHeader : styles.accordionHeader}
            onClick={() => setSelectedSection(selectedSection === "profile" ? null : "profile")}
          >
            Profile Settings
          </div>
          {selectedSection === "profile" && (
            <div className={styles.accordionContent}>
              <form className={styles.form} onSubmit={handleSaveProfile}>
                <div className="row">
                  <div className="col-md-6">
                    <label className={styles.formLabel}>First Name:</label>
                    <input type="text" className={styles.formInput} value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                  </div>
                  <div className="col-md-6">
                    <label className={styles.formLabel}>Last Name:</label>
                    <input type="text" className={styles.formInput} value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label className={styles.formLabel}>Email:</label>
                    <input type="email" className={styles.formInput} value={email} onChange={(e) => setEmail(e.target.value)} required />
                  </div>
                  <div className="col-md-6">
                    <label className={styles.formLabel}>Password:</label>
                    <input type="password" className={styles.formInput} value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                </div>
                <button className="btn btn-primary" type="submit" disabled={isSaving}>
                  {isSaving ? "Saving..." : "Save Profile"}
                </button>
              </form>
            </div>
          )}
        </div>
        
        <div className={styles.accordionItem}>
          <div
            className={selectedSection === "newAdmin" ? styles.activeHeader : styles.accordionHeader}
            onClick={() => setSelectedSection(selectedSection === "newAdmin" ? null : "newAdmin")}
          >
            Add New Admin
          </div>
          {selectedSection === "newAdmin" && (
            <div className={styles.accordionContent}>
              <form className={styles.form} onSubmit={handleAddNewAdmin}>
                <div className="row">
                  <div className="col-md-6">
                    <label className={styles.formLabel}>First Name:</label>
                    <input type="text" className={styles.formInput} value={newAdmin.firstName} onChange={(e) => setNewAdmin({ ...newAdmin, firstName: e.target.value })} required />
                  </div>
                  <div className="col-md-6">
                    <label className={styles.formLabel}>Last Name:</label>
                    <input type="text" className={styles.formInput} value={newAdmin.lastName} onChange={(e) => setNewAdmin({ ...newAdmin, lastName: e.target.value })} required />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label className={styles.formLabel}>Email:</label>
                    <input type="email" className={styles.formInput} value={newAdmin.email} onChange={(e) => setNewAdmin({ ...newAdmin, email: e.target.value })} required />
                  </div>
                  <div className="col-md-6">
                    <label className={styles.formLabel}>Password:</label>
                    <input type="password" className={styles.formInput} value={newAdmin.password} onChange={(e) => setNewAdmin({ ...newAdmin, password: e.target.value })} required />
                  </div>
                </div>
                <button className="btn btn-primary" type="submit" disabled={isSaving}>
                  {isSaving ? "Adding..." : "Add New Admin"}
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsComponent;
