import React, { useEffect } from 'react';
import { FaBox, FaDollarSign, FaUser, FaTruck } from 'react-icons/fa';
import './Insights.css';

const Insights = () => {
  useEffect(() => {
    // Animation effect on component load
    const cards = document.querySelectorAll('.insights-card');
    cards.forEach((card, index) => {
      card.style.animation = `fadeInUp 0.3s ease ${index * 0.1}s forwards`;
    });
  }, []);

  return (
    <div className="insights-dashboard">
      {/* Top Stats Cards */}
      <div className="insights-dashboard__stats">
        <div className="insights-card insights-card--stat">
          <FaBox className="insights-card__icon" />
          <h4 className="insights-card__title">Total Products</h4>
          <p className="insights-card__value">20K+</p>
          <span className="insights-card__subtitle">All time</span>
        </div>
        <div className="insights-card insights-card--stat">
          <FaDollarSign className="insights-card__icon" />
          <h4 className="insights-card__title">Total Sale</h4>
          <p className="insights-card__value">₦100M</p>
          <span className="insights-card__subtitle">This month</span>
        </div>
        <div className="insights-card insights-card--stat">
          <FaUser className="insights-card__icon" />
          <h4 className="insights-card__title">New Customer</h4>
          <p className="insights-card__value">649</p>
          <span className="insights-card__subtitle">This month</span>
        </div>
        <div className="insights-card insights-card--stat">
          <FaTruck className="insights-card__icon" />
          <h4 className="insights-card__title">Total Delivery</h4>
          <p className="insights-card__value">5830</p>
          <span className="insights-card__subtitle">This month</span>
        </div>
      </div>

      {/* Charts Section */}
      <div className="insights-dashboard__charts">
        <div className="insights-card insights-card--chart">
          <h4 className="insights-card__title">Revenue Summary</h4>
          <div className="insights-card__chart-placeholder">Chart</div>
        </div>
        <div className="insights-card insights-card--chart">
          <h4 className="insights-card__title">Sale Summary</h4>
          <div className="insights-card__chart-placeholder">Chart</div>
        </div>
        <div className="insights-card insights-card--promo">
          <h4 className="insights-card__promo-title">Explore more insights on campaign performance!</h4>
          <a href="." className="insights-card__promo-link">Get More Insights</a>
        </div>
      </div>

      {/* Recent Orders */}
      <div className="insights-dashboard__orders">
        <h4 className="insights-dashboard__orders-title">Recent Orders</h4>
        <div className="insights-orders__header">
          <span>Transaction ID</span>
          <span>Product</span>
          <span>Payment</span>
          <span>Order Date</span>
          <span>Status</span>
          <span>Action</span>
        </div>
        <div className="insights-order">
          <span>#75845738895</span>
          <span>Macbook Pro M3</span>
          <span>Paystack</span>
          <span>22 Jan 2022</span>
          <span className="status shipped"><FaTruck className="status-icon" /> Shipped</span>
          <span>...</span>
        </div>
        {/* Additional orders can go here */}
      </div>
    </div>
  );
};

export default Insights;
