import React from 'react';
import './preloader.css';

const Preloader = () => {
  return (
    <div className="txt-loading">
      <span data-text-preloader="F" className="letters-loading">F</span>
      <span data-text-preloader="A" className="letters-loading">A</span>
      <span data-text-preloader="Y" className="letters-loading">Y</span>
      <span data-text-preloader="O" className="letters-loading">O</span>
      <span data-text-preloader="Z" className="letters-loading">Z</span>
      <span data-text-preloader="S" className="letters-loading">S</span>
      <span data-text-preloader="T" className="letters-loading">T</span>
      <span data-text-preloader="A" className="letters-loading">A</span>
      <span data-text-preloader="R" className="letters-loading">R</span>
      {/* <span data-text-preloader="N" className="letters-loading">N</span>
      <span data-text-preloader="A" className="letters-loading">A</span>
      <span data-text-preloader="T" className="letters-loading">T</span>
      <span data-text-preloader="U" className="letters-loading">U</span>
      <span data-text-preloader="R" className="letters-loading">R</span>
      <span data-text-preloader="A" className="letters-loading">A</span>
      <span data-text-preloader="L" className="letters-loading">L</span>
      <span data-text-preloader="S" className="letters-loading">S</span> */}
    </div>
  );
};

export default Preloader;
