import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import './Category.css';

const CategoryPage = () => {
    const [categories, setCategories] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);  // To check if editing or adding
    const [editCategoryId, setEditCategoryId] = useState(null);  // Store ID of category being edited

    const apiUrl = process.env.REACT_APP_API_URL; // Use the backend URL from the environment variable

    useEffect(() => {
        setLoading(true);
        axios.get(`${apiUrl}/api/admin/categories`)
            .then(response => {
                setCategories(response.data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to fetch categories!',
                });
            });
    }, [apiUrl]);

    const addOrUpdateCategory = (e) => {
        e.preventDefault();

        if (!name) {
            Swal.fire({
                icon: 'warning',
                title: 'Validation Error',
                text: 'Category name is required!',
            });
            return;
        }

        setLoading(true);

        // Determine whether to update or add a new category
        if (isEdit) {
            // Update category
            axios.put(`${apiUrl}/api/admin/categories/${editCategoryId}`, { name, description })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Category Updated',
                        text: response.data.message,
                    });
                    setCategories(categories.map(cat => 
                        cat.id === editCategoryId ? { ...cat, name, description } : cat
                    ));
                    resetForm();
                })
                .catch(error => {
                    setLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Failed to update category!',
                    });
                });
        } else {
            // Add new category
            axios.post(`${apiUrl}/api/admin/categories/add`, { name, description })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Category Added',
                        text: response.data.message,
                    });
                    setCategories([...categories, { id: response.data.id, name, description }]);
                    resetForm();
                })
                .catch(error => {
                    setLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Failed to add category!',
                    });
                });
        }
    };

    const deleteCategory = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#007bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${apiUrl}/api/admin/categories/${id}`)
                    .then(response => {
                        Swal.fire(
                            'Deleted!',
                            'Category has been deleted.',
                            'success'
                        );
                        setCategories(categories.filter(category => category.id !== id));
                    })
                    .catch(error => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Failed to delete category!',
                        });
                    });
            }
        });
    };

    const openEditModal = (category) => {
        setIsEdit(true);
        setName(category.name);
        setDescription(category.description);
        setEditCategoryId(category.id);
        setIsModalOpen(true);
    };

    const resetForm = () => {
        setName('');
        setDescription('');
        setIsEdit(false);
        setEditCategoryId(null);
        setLoading(false);
        setIsModalOpen(false);
    };

    return (
        <div className="category-page">
            <h1>Category Management</h1>

            <button className="open-modal-btn" onClick={() => setIsModalOpen(true)}>
                {isEdit ? 'Edit Category' : 'Add New Category'}
            </button>

            <ul className="category-list">
                {loading ? (
                    <p className="loading">Loading categories...</p>
                ) : (
                    categories.map(category => (
                        <li key={category.id} className="category-item">
                            <div className="category-content">
                                <h3>{category.name}</h3>
                                <p>{category.description}</p>
                            </div>
                            <button className="edit-btn" onClick={() => openEditModal(category)}>Edit</button>
                            <button className="delete-btn" onClick={() => deleteCategory(category.id)}>Delete</button>
                        </li>
                    ))
                )}
            </ul>

            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <button className="close-modal-btn" onClick={resetForm}>×</button>
                        <form className="category-form" onSubmit={addOrUpdateCategory}>
                            <h2>{isEdit ? 'Edit Category' : 'Add New Category'}</h2>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Category Name"
                                required
                            />
                            <textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Category Description"
                            ></textarea>
                            <button type="submit" className="submit-btn">
                                {loading ? (isEdit ? 'Updating...' : 'Adding...') : (isEdit ? 'Update Category' : 'Add Category')}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CategoryPage;
