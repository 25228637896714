import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { FaSearch, FaBell } from 'react-icons/fa';
import styles from './Overview.module.css';
import axios from 'axios';
import './DashboardHeader.css';

const apiUrl = process.env.REACT_APP_API_URL;

function DashboardHeader() {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');
  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No token found, please log in.');

        const { data } = await axios.get(`${apiUrl}/api/user/details`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserData(data);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError(
          err.response?.status === 401
            ? 'Unauthorized: Please log in again.'
            : 'Failed to fetch user data.'
        );
      }
    };

    fetchUserData();

    const hour = new Date().getHours();
    setGreeting(hour < 12 ? 'Good morning' : hour < 18 ? 'Good afternoon' : 'Good evening');
  }, []);

  if (error) return <div className={styles.error}>{error}</div>;
  if (!userData) return <div className={styles.loader}><CircularProgress /></div>;

  return (
    <header className="dashboard-header-container sticky">
      <div className="dashboard-header">
      <h2>{greeting}, {userData?.firstName || 'User'}!</h2>
        </div>
        <div className="dashboard-search">
          <input type="text" placeholder="Search" className="dashboard-search-input" />
          <FaSearch className="dashboard-search-icon" />
        </div>
        <div className="dashboard-actions">
          <FaBell className="dashboard-notification-icon" />
          <div className="dashboard-user-info">
            <img src="user-avatar.jpg" alt="User Avatar" className="dashboard-user-avatar" />
            <span className="dashboard-user-name">{userData?.firstName || 'User'}</span>
          </div>
        </div>
    </header>
  );
}

export default DashboardHeader;
