import React, { useState } from 'react';
import './TransactionHistory.css';

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([
    {
      id: 'TRX123456',
      date: '2023-09-21',
      items: ['Laptop', 'Mouse', 'Keyboard'],
      amount: '$1050.00',
      status: 'Completed',
      paymentMethod: 'Credit Card',
      expanded: false,
    },
    {
      id: 'TRX987654',
      date: '2023-09-15',
      items: ['Headphones'],
      amount: '$200.00',
      status: 'Pending',
      paymentMethod: 'PayPal',
      expanded: false,
    },
    {
      id: 'TRX987654',
      date: '2023-09-15',
      items: ['Headphones'],
      amount: '$200.00',
      status: 'Pending',
      paymentMethod: 'PayPal',
      expanded: false,
    },
    {
      id: 'TRX987654',
      date: '2023-09-15',
      items: ['Headphones'],
      amount: '$200.00',
      status: 'Pending',
      paymentMethod: 'PayPal',
      expanded: false,
    },
    {
      id: 'TRX987654',
      date: '2023-09-15',
      items: ['Headphones'],
      amount: '$200.00',
      status: 'Pending',
      paymentMethod: 'PayPal',
      expanded: false,
    },
    {
      id: 'TRX987654',
      date: '2023-09-15',
      items: ['Headphones'],
      amount: '$200.00',
      status: 'Pending',
      paymentMethod: 'PayPal',
      expanded: false,
    },
    {
      id: 'TRX987654',
      date: '2023-09-15',
      items: ['Headphones'],
      amount: '$200.00',
      status: 'Pending',
      paymentMethod: 'PayPal',
      expanded: false,
    },
    {
      id: 'TRX987654',
      date: '2023-09-15',
      items: ['Headphones'],
      amount: '$200.00',
      status: 'Pending',
      paymentMethod: 'PayPal',
      expanded: false,
    },
    {
      id: 'TRX987654',
      date: '2023-09-15',
      items: ['Headphones'],
      amount: '$200.00',
      status: 'Pending',
      paymentMethod: 'PayPal',
      expanded: false,
    },
    {
      id: 'TRX987654',
      date: '2023-09-15',
      items: ['Headphones'],
      amount: '$200.00',
      status: 'Pending',
      paymentMethod: 'PayPal',
      expanded: false,
    },
    {
      id: 'TRX987654',
      date: '2023-09-15',
      items: ['Headphones'],
      amount: '$200.00',
      status: 'Pending',
      paymentMethod: 'PayPal',
      expanded: false,
    },
    {
      id: 'TRX987654',
      date: '2023-09-15',
      items: ['Headphones'],
      amount: '$200.00',
      status: 'Pending',
      paymentMethod: 'PayPal',
      expanded: false,
    },
    {
      id: 'TRX987654',
      date: '2023-09-15',
      items: ['Headphones'],
      amount: '$200.00',
      status: 'Pending',
      paymentMethod: 'PayPal',
      expanded: false,
    },
    // Additional transactions...
  ]);

  const toggleExpand = (id) => {
    setTransactions(transactions.map(tx => (
      tx.id === id ? { ...tx, expanded: !tx.expanded } : tx
    )));
  };

  return (
    <div className="transaction-history">
      {/* Header Section */}
      <div className="header">
        <h2>Transaction History</h2>
        <div className="filters">
          <input type="text" placeholder="Search transactions..." />
          <button className="filter-btn">Last 7 Days</button>
          <button className="filter-btn">Completed</button>
          <button className="export-btn">Export CSV</button>
        </div>
      </div>

      {/* Transaction Table */}
      <div className="transaction-table">
        <table>
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>Date</th>
              <th>Items</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((tx) => (
              <>
                <tr key={tx.id} onClick={() => toggleExpand(tx.id)} className="transaction-row">
                  <td>{tx.id}</td>
                  <td>{tx.date}</td>
                  <td>
                    {tx.items.slice(0, 2).join(', ')}
                    {tx.items.length > 2 && <span> +{tx.items.length - 2} more</span>}
                  </td>
                  <td>{tx.amount}</td>
                  <td>
                    <span className={`status-badge ${tx.status.toLowerCase()}`}>{tx.status}</span>
                  </td>
                  <td>
                    <button className="action-btn">Invoice</button>
                    <button className="action-btn">Re-order</button>
                  </td>
                </tr>

                {/* Expanded Details */}
                {tx.expanded && (
                  <tr className="transaction-details">
                    <td colSpan="6">
                      <div className="details-container">
                        <h4>Transaction Details for {tx.id}</h4>
                        <p><strong>Payment Method:</strong> {tx.paymentMethod}</p>
                        <p><strong>Shipping Address:</strong> 1234 Street, City, Country</p>
                        <div className="items">
                          <strong>Items Purchased:</strong>
                          <ul>
                            {tx.items.map((item, idx) => (
                              <li key={idx}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransactionHistory;
