// src/components/ProductPage.js
import React from 'react';
import './ProductPage.css';

const ProductPage = () => {
    const products = [
        { id: 1, name: "Minimalist Chair", price: "$50", image: "https://buybetter.ng/wp-content/uploads/2024/06/Amlactin.png" },
        { id: 2, name: "Elegant Sofa", price: "$75", image: "https://buybetter.ng/wp-content/uploads/2024/06/Amlactin.png" },
        { id: 3, name: "Modern Table", price: "$100", image: "https://buybetter.ng/wp-content/uploads/2024/06/Amlactin.png" },
        { id: 4, name: "Classic Lamp", price: "$125", image: "https://buybetter.ng/wp-content/uploads/2024/06/Amlactin.png" },
        { id: 5, name: "Wall Art", price: "$150", image: "https://buybetter.ng/wp-content/uploads/2024/06/Amlactin.png" },
        { id: 6, name: "Vintage Clock", price: "$60", image: "https://buybetter.ng/wp-content/uploads/2024/06/Amlactin.png" },
        { id: 7, name: "Comfy Couch", price: "$85", image: "https://buybetter.ng/wp-content/uploads/2024/06/Amlactin.png" },
        { id: 8, name: "Stylish Desk", price: "$110", image: "https://buybetter.ng/wp-content/uploads/2024/06/Amlactin.png" },
        { id: 9, name: "Wooden Cabinet", price: "$135", image: "https://buybetter.ng/wp-content/uploads/2024/06/Amlactin.png" },
        { id: 10, name: "Decorative Vase", price: "$160", image: "https://buybetter.ng/wp-content/uploads/2024/06/Amlactin.png" },
        { id: 11, name: "Pendant Light", price: "$55", image: "https://buybetter.ng/wp-content/uploads/2024/06/Amlactin.png" },
        { id: 12, name: "Leather Armchair", price: "$95", image: "https://buybetter.ng/wp-content/uploads/2024/06/Amlactin.png" },
        { id: 13, name: "Marble Coffee Table", price: "$120", image: "https://buybetter.ng/wp-content/uploads/2024/06/Amlactin.png" },
        { id: 14, name: "Velvet Ottoman", price: "$145", image: "https://buybetter.ng/wp-content/uploads/2024/06/Amlactin.png" },
        { id: 15, name: "Crystal Chandelier", price: "$170", image: "https://buybetter.ng/wp-content/uploads/2024/06/Amlactin.png" },
    ];

    return (
        <div className="contact-us-page">
            {/* Full-width header with modern look */}
            <div className="breadcrumb-image">
                <div className="breadcrumb-overlay">
                    <h1>Explore Our Collection</h1>
                </div>
            </div>

            <h1 className="page-title">Shop Our Products</h1>

            <div className="product-grid">
                {products.map(product => (
                    <div key={product.id} className="product-card">
                        <div className="image-container">
                            <img src={product.image} alt={product.name} className="product-image" />
                        </div>
                        <h2 className="product-name">{product.name}</h2>
                        <p className="product-price">{product.price}</p>
                        <button className="add-to-cart">Add to Cart</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductPage;
