import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Users.css';

const apiUrl = process.env.REACT_APP_API_URL;

const Users = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ firstName: '', lastName: '', email: '', password: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    axios
      .get(`${apiUrl}/api/admin/users`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then(response => setUsers(response.data))
      .catch(() => setError('Failed to fetch users. Please try again.'));
  };

  const handleInputChange = (e) => setNewUser({ ...newUser, [e.target.name]: e.target.value });

  const handleAddUser = (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    axios
      .post(`${apiUrl}/api/admin/user/add`, newUser, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then(response => {
        setSuccess('User added successfully.');
        setUsers([...users, response.data]);
        setNewUser({ firstName: '', lastName: '', email: '', password: '' });
        setShowForm(false);
      })
      .catch((error) => setError(error.response?.data?.error || 'Failed to add user.'));
  };

  const handleDelete = (id) => {
    if (!window.confirm('Are you sure you want to delete this user?')) return;

    axios
      .delete(`${apiUrl}/api/admin/user/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then(() => {
        setUsers(users.filter(user => user.id !== id));
        setSuccess('User deleted successfully.');
      })
      .catch(() => setError('Failed to delete user.'));
  };

  const handleSuspend = (id) => {
    axios
      .put(`${apiUrl}/api/admin/user/suspend/${id}`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then(() => {
        setUsers(users.map(user => user.id === id ? { ...user, status: 'suspended' } : user));
        setSuccess('User suspended successfully.');
      })
      .catch(() => setError('Failed to suspend user.'));
  };

  return (
    <div className="users-container">
      <h1>User Management</h1>

      {success && <p className="message success">{success}</p>}
      {error && <p className="message error">{error}</p>}

      <button onClick={() => setShowForm(true)} className="add-user-button">+ Add New User</button>

      {showForm && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={() => setShowForm(false)}>&times;</span>
            <h2>Add New User</h2>
            <form onSubmit={handleAddUser} className="form">
              <input type="text" name="firstName" value={newUser.firstName} onChange={handleInputChange} placeholder="First Name" required className="input" />
              <input type="text" name="lastName" value={newUser.lastName} onChange={handleInputChange} placeholder="Last Name" required className="input" />
              <input type="email" name="email" value={newUser.email} onChange={handleInputChange} placeholder="Email" required className="input" />
              <input type="password" name="password" value={newUser.password} onChange={handleInputChange} placeholder="Password" required className="input" />
              <button type="submit" className="submit-button">Add User</button>
            </form>
          </div>
        </div>
      )}

      <table className="users-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 ? (
            users.map(user => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.first_name} {user.last_name}</td>
                <td>{user.email}</td>
                <td>{user.status || 'active'}</td>
                <td>
                  <button onClick={() => handleDelete(user.id)} className="delete-button">Delete</button>
                  <button onClick={() => handleSuspend(user.id)} className="suspend-button" disabled={user.status === 'suspended'}>
                    {user.status === 'suspended' ? 'Suspended' : 'Suspend'}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No users found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Users;
