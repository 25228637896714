import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../components/MobileHeader/mobile-header.css';
import Logo from '../../assets/images/Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faShoppingCart, faTimes } from '@fortawesome/free-solid-svg-icons'; // Keep faTimes here
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'; // Move brand icons here

function MobileHeader() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuClick = () => {
    setMenuOpen(false);
  };

  return (
    <header className="mobile-header">
      <div className="logo-mobile">
        <img src={Logo} alt="Logo" />
      </div>
      <div className={`hamburger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className={`mobile-menu ${menuOpen ? 'open' : ''}`}>
        <div className="menu-header">
          <img src={Logo} alt="Logo" className="sidebar-logo" />
          <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={toggleMenu} />
        </div>
        <ul>
          <li><Link to="/" onClick={handleMenuClick}>Home</Link></li>
          <li><Link to="/about" onClick={handleMenuClick}>About Us</Link></li>
          <li><Link to="/shop" onClick={handleMenuClick}>Shop</Link></li>
          <li><Link to="/contact-us" onClick={handleMenuClick}>Contact Us</Link></li>
        </ul>
        <div className="menu-icons">
          <FontAwesomeIcon icon={faUser} className="menu-icon" />
          <FontAwesomeIcon icon={faShoppingCart} className="menu-icon" />
        </div>
        <div className="social-media">
          <a href="https://web.facebook.com/FayozStarNaturals/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} className="social-icon" />
          </a>
          <a href="https://x.com/FayozStar" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} className="social-icon" />
          </a>
          <a href="https://instagram.com/fayoz_star" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} className="social-icon" />
          </a>
        </div>
        <div className="copyright">© {new Date().getFullYear()} Fayozstar Naturals. All Rights Reserved.</div>
      </nav>
      <div className={`overlay ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}></div>
    </header>
  );
}

export default MobileHeader;