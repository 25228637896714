import React from "react";

const AddressList = ({ addresses, defaultAddress, onSetDefault, onDelete }) => {
  return (
    <div className="address-list">
      {addresses.map((address, index) => (
        <div key={index} className="address-card">
          {index === defaultAddress && <span className="default-badge">Default</span>}
          <p>{address.name}</p>
          <p>{address.address}</p>
          <p>{address.city}</p>
          <p>{address.country}</p>
          <div className="address-actions">
            <button className="edit-btn" onClick={() => onSetDefault(index)}>
              Set Default
            </button>
            <button className="delete-btn" onClick={() => onDelete(index)}>
              Delete
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddressList;
