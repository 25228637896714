import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Reviews.css';
import googleLogo from './Google__G__logo.svg';

// Import Swiper and modules
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';

const ReviewComponent = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const placeId = 'ChIJXQ7psAuJRxARISCBKexmack'; 
  const apiKey = 'AIzaSyA_78oEBNuxkJXn4Hj_hCEAyJRaMaqwEKw';

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(
          `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${apiKey}`
        );
        setReviews(response.data.result.reviews || []);
      } catch (err) {
        console.error('Error fetching reviews:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  if (loading) return <p>Loading reviews...</p>;
  if (error) return <p>Error loading reviews: {error}</p>;

  const handleShowMore = (index) => {
    const updatedReviews = reviews.map((review, i) => 
      i === index ? { ...review, showMore: !review.showMore } : review
    );
    setReviews(updatedReviews);
  };

  return (
    <div className="reviews-container">
      <h1>Our Reviews</h1>
      {reviews.length > 0 ? (
        <Swiper
          modules={[Navigation, Pagination, A11y, Autoplay]}
          spaceBetween={20}
          slidesPerView={3}
          breakpoints={{
            320: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
        >
          {reviews.map((review, index) => (
            <SwiperSlide key={index}>
              <div className="review-card">
                <div className="review-header">
                  <img
                    src={`https://ui-avatars.com/api/?name=${encodeURIComponent(review.author_name)}&background=random`}
                    alt={review.author_name}
                    className="author-image"
                  />
                  <div className="author-details">
                    <span className="author-name">{review.author_name}</span>
                    <span className="time-ago">
                      {new Date(review.time * 1000).toLocaleDateString()}
                    </span>
                  </div>
                  <div className="google-badge">
                    <img src={googleLogo} alt="Google Logo" />
                  </div>
                </div>
                <div className="review-content">
                  {review.text.length > 150 && !review.showMore ? (
                    <>
                      {review.text.substring(0, 150)}...
                      <span className="show-more" onClick={() => handleShowMore(index)}>
                        Show more
                      </span>
                    </>
                  ) : (
                    <>
                      {review.text}
                      {review.text.length > 150 && (
                        <span className="show-more" onClick={() => handleShowMore(index)}>
                          Show less
                        </span>
                      )}
                    </>
                  )}
                </div>
                <div className="review-footer">
                  <div className="rating-stars">
                    {'⭐'.repeat(review.rating)}
                  </div>
                  <div className="rating-value">{review.rating} / 5</div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p>No reviews available.</p>
      )}
    </div>
  );
};

export default ReviewComponent;
