import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import './Settings.css';

const apiUrl = process.env.REACT_APP_API_URL; // Use the backend URL from the environment variable

const Settings = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    phone: '',
  });
  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  // Fetch user details on component mount
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('token'); // Assume token is stored in localStorage
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const res = await axios.get(`${apiUrl}/api/user/details`, config);
        setUser(res.data);
      } catch (err) {
        console.error(err);
        Swal.fire('Error', 'Could not fetch user details.', 'error');
      }
    };

    fetchUserDetails();
  }, []);

  const handleInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const handleUpdateProfile = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await axios.put(`${apiUrl}/api/user/update`, user, config);
      Swal.fire('Success', 'Profile updated successfully!', 'success');
    } catch (err) {
      console.error(err);
      Swal.fire('Error', 'Profile update failed.', 'error');
    }
  };

  const handleChangePassword = async () => {
    // Check if any field is empty
    if (!passwords.oldPassword || !passwords.newPassword || !passwords.confirmPassword) {
      Swal.fire('Error', 'All password fields are required.', 'error');
      return;
    }
  
    // Check if new password and confirm password match
    if (passwords.newPassword !== passwords.confirmPassword) {
      Swal.fire('Error', 'New password and confirm password do not match.', 'error');
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await axios.post(`${apiUrl}/api/user/change-password`, passwords, config);
      Swal.fire('Success', 'Password changed successfully!', 'success');
    } catch (err) {
      console.error(err);
      Swal.fire('Error', 'Password change failed.', 'error');
    }
  };
  

  const handleDeleteAccount = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "This action will permanently delete your account and all associated data. This action is irreversible.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete my account!',
      cancelButtonText: 'No, keep my account',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = localStorage.getItem('token');
          const config = {
            headers: { Authorization: `Bearer ${token}` },
          };
          await axios.delete(`${apiUrl}/api/user/delete`, config);
          Swal.fire('Deleted!', 'Your account has been deleted.', 'success');
          localStorage.removeItem('token');
          window.location.href = '/login';
        } catch (err) {
          console.error(err);
          Swal.fire('Error', 'Failed to delete account.', 'error');
        }
      }
    });
  };

  return (
    <div className="settings-container">
      {/* Tab Navigation */}
      <div className="tabs">
        <button
          className={`tab ${activeTab === 'profile' ? 'active' : ''}`}
          onClick={() => setActiveTab('profile')}
        >
          Profile
        </button>
        <button
          className={`tab ${activeTab === 'password' ? 'active' : ''}`}
          onClick={() => setActiveTab('password')}
        >
          Change Password
        </button>
        <button
          className={`tab ${activeTab === 'delete' ? 'active' : ''}`}
          onClick={() => setActiveTab('delete')}
        >
          Delete Account
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === 'profile' && (
        <div className="profile-content">
        <div className="form-row">
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={user.firstName}
              onChange={handleInputChange}
            />
          </div>      
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={user.lastName}
              onChange={handleInputChange}
            />
          </div>
        </div>      
        <div className="form-row">
          <div className="form-group">
            <label>Date of Birth</label>
            <input
              type="date"
              name="dob"
              value={user.dob ? user.dob.split('T')[0] : ''}
              onChange={handleInputChange}
            />
          </div>      
          <div className="form-group">
            <label>Email</label>
            <input type="email" value={user.email} disabled />
          </div>
        </div>      
        <div className="form-group">
          <label>Phone</label>
          <input
            type="text"
            name="phone"
            value={user.phone}
            onChange={handleInputChange}
          />
        </div>      
        <button className="update-btn" onClick={handleUpdateProfile}>
          Update
        </button>
      </div>      
      )}

      {activeTab === 'password' && (
        <div className="password-content">
        {/* Password change form */}
        <div className="form-group">
          <label>Old Password</label>
          <input
            type="password"
            name="oldPassword"
            value={passwords.oldPassword}
            onChange={handlePasswordChange}
            required
          />
        </div>      
        <div className="form-group">
          <label>New Password</label>
          <input
            type="password"
            name="newPassword"
            value={passwords.newPassword}
            onChange={handlePasswordChange}
            required
          />
        </div>      
        <div className="form-group">
          <label>Confirm Password</label>
          <input
            type="password"
            name="confirmPassword"
            value={passwords.confirmPassword}
            onChange={handlePasswordChange}
            required
          />
        </div>      
        <button className="update-btn" onClick={handleChangePassword}>
          Change Password
        </button>
      </div>      
      )}

      {activeTab === 'delete' && (
        <div className="delete-account-content">
          <h3 className="delete-account-title">Delete Account</h3>
          <p className="delete-account-description">
            This action will permanently delete your account and all associated data. This action is irreversible. Please be sure before proceeding.
          </p>
          <button className="delete-account-btn" onClick={handleDeleteAccount}>
            Delete your account
          </button>
        </div>
      )}
    </div>
  );
};

export default Settings;
