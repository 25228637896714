import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import Users from './User';
import SettingsComponent from './SettingsComponent.js';
import AdminHeader from './AdminHeader.js';
import './cp.css';
import Insights from './Insights.js';
import AttributesDashboard from './Attributes.js';
import AdminEmailForm from './SendEmail.js';
import ProtectedRoute from './ProtectedRoute';
import CreateProduct from './CreateProduct.js';
import FileManager from './FileManager.js';
import CategoryPage from './Category.js';

const AdminDashboard = () => {
    return (
        <div className="admin-dashboard">
            <AdminSidebar />
            <div className="main-area">
                <AdminHeader />
                <div className="content-area">
                    <Routes>
                        {/* Redirect to Insights if no specific path is provided */}
                        <Route 
                            path="/" 
                            element={
                                <ProtectedRoute>
                                    <Navigate to="insights" />
                                </ProtectedRoute>
                            } 
                        />
                        <Route 
                            path="insights" 
                            element={
                                <ProtectedRoute>
                                    <Insights />
                                </ProtectedRoute>
                            } 
                        />
                        <Route 
                            path="users" 
                            element={
                                <ProtectedRoute>
                                    <Users />
                                </ProtectedRoute>
                            } 
                        />
                        <Route 
                            path="product/attributes" 
                            element={
                                <ProtectedRoute>
                                    <AttributesDashboard/>
                                </ProtectedRoute>
                            } 
                        />
                        <Route 
                            path="setting" 
                            element={
                                <ProtectedRoute>
                                    <SettingsComponent />
                                </ProtectedRoute>
                            } 
                        />
                        <Route 
                            path="product/create" 
                            element={
                                <ProtectedRoute>
                                    <CreateProduct/>
                                </ProtectedRoute>
                            } 
                        />
                        <Route 
                            path="media" 
                            element={
                                <ProtectedRoute>
                                    <FileManager />
                                </ProtectedRoute>
                            } 
                        />
                        <Route 
                            path="send-mail" 
                            element={
                                <ProtectedRoute>
                                    <AdminEmailForm />
                                </ProtectedRoute>
                            } 
                        />
                        <Route 
                            path="product/category" 
                            element={
                                <ProtectedRoute>
                                    <CategoryPage />
                                </ProtectedRoute>
                            } 
                        />
                        {/* Add other protected routes here */}
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;
