// src/components/Header.js
import React from 'react';
import './header.css';
import { FaMapMarkerAlt, FaPhone, FaHeart, FaShoppingCart, FaUser, FaSearch, FaBars } from 'react-icons/fa';

const Header = () => {
  return (
    <>
      {/* Topbar */}
      <div className="topbar py-2">
        <div className="container d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <FaMapMarkerAlt className="icon me-2" />
            <span>1418 Riverwood Drive, CA 96052, US</span>
          </div>
          <div className="d-flex align-items-center">
            <span className="text-muted me-3"><strong>Order Tracking</strong></span>
            <span className="text-muted">Currency: <strong>USD</strong></span>
          </div>
        </div>
      </div>

      {/* Main Header (Sticky Part) */}
      <header className="main-header sticky-header py-3">
        <div className="container d-flex justify-content-between align-items-center">
          
          {/* All Categories Dropdown */}
          <div className="categories-dropdown">
            <button className="categories-btn">
              <FaBars className="me-2" /> All Categories
            </button>
            <div className="dropdown-menu">
              <ul>
                <li><a href="/">Electronics</a></li>
                <li><a href="/">Fashion</a></li>
                <li><a href="/">Home & Garden</a></li>
                <li><a href="/">Toys</a></li>
                <li><a href="/">Sports</a></li>
                <li><a href="/">Automotive</a></li>
              </ul>
            </div>
          </div>

          {/* Logo */}
          <div className="logo">
            <h1 className="brand-name">Fayozstar<span className="dot">.</span></h1>
          </div>

          {/* Search Section */}
          <div className="search-bar d-flex align-items-center">
            <div className="search-input">
              <input type="text" className="form-control" placeholder="Search for products..." />
              <button className="search-btn">
                <FaSearch />
              </button>
            </div>
          </div>

          {/* Icons Section */}
          <div className="icons d-flex align-items-center">
            <a href="tel:9032049655" className="icon-link me-4">
              <FaPhone className="icon" />
            </a>
            <a href="/" className="icon-link me-4">
              <FaHeart className="icon" />
            </a>
            <a href="/" className="icon-link me-4 position-relative">
              <FaShoppingCart className="icon" />
              <span className="cart-count">3</span> {/* Example cart count */}
            </a>
            <a href="login" className="icon-link">
              <FaUser className="icon" />
            </a>
          </div>
        </div>
      </header>

      {/* Navigation Menu (Non-Sticky Part) */}
      {/* <nav className="navbar mt-4">
        <div className="container">
          <ul className="nav justify-content-end">
            <li className="nav-item">
              <a className="nav-link" href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/">Shop</a></li>
            <li className="nav-item">
              <a className="nav-link" href="/">Products</a></li>
            <li className="nav-item">
              <a className="nav-link" href="/">Blog</a></li>
            <li className="nav-item">
              <a className="nav-link" href="/">Pages</a></li>
            <li className="nav-item">
              <a className="nav-link" href="contact-us">Contact</a>
            </li>
          </ul>
        </div>
      </nav> */}
    </>
  );
};

export default Header;
