import React from 'react';
import styles from './Overview.module.css';
import { FaChartLine, FaUsers, FaBox, FaClipboardList } from 'react-icons/fa';

const Overview = () => {
  return (
    <div className={styles.container}>
      {/* <header className={styles.welcomeBanner}>
        <h2>{greeting}, {userData?.firstName || 'User'}!</h2>
        <p>Here’s a quick look at your activities.</p>
      </header> */}

      <section className={styles.statsCards}>
        <StatCard icon={<FaChartLine />} title="Total Spent" value="₦4,000,000.00" label="This Week" />
        <StatCard icon={<FaUsers />} title="Customers" value="1,250 Active" label="This Week" />
        <StatCard icon={<FaBox />} title="Saved Orders" value="45 Active" label="This Week" />
        <StatCard icon={<FaClipboardList />} title="Recent Orders" value="450" label="This Week" />
      </section>

      <section className={styles.chartSection}>
        <h3>Activity Summary</h3>
        <div className={styles.chart}>[Graph Component Placeholder]</div>
      </section>

      <TableSection />
      <RecentOrders />
    </div>
  );
};

const StatCard = ({ icon, title, value, label }) => (
  <div className={`${styles.card}`}>
    <div className={styles.iconContainer}>{icon}</div>
    <h3>{title}</h3>
    <p>{value}</p>
    <span className={styles.cardLabel}>{label}</span>
  </div>
);

const TableSection = () => (
  <section className={styles.tableSection}>
    <h3>Top Buying Product</h3>
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Product</th>
          <th>Price</th>
          <th>Orders</th>
          <th>Stock</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr className={styles.tableRow}>
          <td>Apple AirPods Pro</td>
          <td>$730.00</td>
          <td>47</td>
          <td>38</td>
          <td>$34,310.00</td>
        </tr>
        <tr className={styles.tableRow}>
          <td>iPhone 13 Pro Max</td>
          <td>$1,099.00</td>
          <td>30</td>
          <td>25</td>
          <td>$32,970.00</td>
        </tr>
      </tbody>
    </table>
  </section>
);

const RecentOrders = () => (
  <section className={styles.recentOrders}>
    <h3>Recent Orders</h3>
    <OrderItem product="iPhone 13 Pro Max" price="$730.00" status="Pending" />
    <OrderItem product="Apple AirPods Pro" price="$730.00" status="Completed" />
  </section>
);

const OrderItem = ({ product, price, status }) => (
  <div className={styles.order}>
    <p>{product}</p>
    <span>{price} × 1</span>
    <span className={status === 'Pending' ? styles.statusPending : styles.statusCompleted}>{status}</span>
  </div>
);

export default Overview;
