import React from 'react';
import styles from './AdminHeader.module.css';
import { FaBell } from 'react-icons/fa'; // Import FontAwesome bell icon

function AdminHeader() {
    return (
        <header className={styles.header}>
            <div className={styles.greeting}>
                <span className={styles.helloText}>Hello,</span>
                <span className={styles.userName}>Pelumi</span>
                <span role="img" aria-label="badge" className={styles.badge}>🌟</span>
            </div>
            <div className={styles.searchContainer}>
                <input
                    type="text"
                    placeholder="Search your products"
                    className={styles.searchInput}
                />
            </div>
            <div className={styles.userSection}>
                <div className={styles.notificationButton}>
                    <FaBell className={styles.notificationIcon} /> {/* Using FontAwesome icon */}
                    <span className={styles.notificationBadge}>3</span> {/* Notification count */}
                </div>
                <img
                    src="https://via.placeholder.com/36"
                    alt="User Avatar"
                    className={styles.avatar}
                />
                <span className={styles.userName}>Pelumi</span>
            </div>
        </header>
    );
}

export default AdminHeader;
