import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AdminLogin.css';
import Logo from '../../assets/images/Logo.png';

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL; // Use the backend URL from the environment variable

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${apiUrl}/api/admin/login`, { email, password });
            const token = response.data.token;

            // Store the token in local storage or context
            localStorage.setItem('adminToken', token);

            Swal.fire({
                icon: 'success',
                title: 'Login Successful',
                text: 'Welcome back, Admin!',
            });

            // Redirect to admin dashboard or another page
            window.location.href = '/cp';
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Login Failed',
                text: error.response?.data.error || 'Something went wrong!',
            });
        }
    };

    return (
        <div className="admin-login-container d-flex justify-content-center align-items-center">
            <div className="admin-login shadow-lg p-4 rounded">
                {/* Logo added above the form */}
                <img src={Logo} alt="Admin Logo" className="admin-login-logo" />
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <div className="form-group password-input">
                        <label htmlFor="password">Password</label>
                        <div className="input-wrapper">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your password"
                                required
                            />
                            <span
                                className="password-toggle"
                                onClick={() => setShowPassword(!showPassword)}
                                style={{ cursor: 'pointer' }}
                            >
                                {showPassword ? '🙈' : '👁️'}
                            </span>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Login</button>
                </form>
                <p className="text-center mt-3">
                    <a href="/forgot-password" className="text-muted">Forgot password?</a>
                </p>
            </div>
        </div>
    );
};

export default AdminLogin;
