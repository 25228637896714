import React, { useState } from "react";
import AddressForm from "./AddressForm";
import AddressList from "./AddressList";
import "./address.css"; // Include the CSS file you provided

const Address = () => {
  const [addresses, setAddresses] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Add new address to the list
  const handleAddAddress = (newAddress) => {
    setAddresses([...addresses, newAddress]);
    setIsModalOpen(false); // Close modal after saving address
  };

  // Set an address as default
  const handleSetDefault = (index) => {
    setDefaultAddress(index);
  };

  // Delete an address from the list
  const handleDelete = (index) => {
    setAddresses(addresses.filter((_, i) => i !== index));
    if (defaultAddress === index) setDefaultAddress(null);
  };

  return (
    <div className="address-container">
      <h2>My Addresses</h2>
      <button onClick={() => setIsModalOpen(true)} className="add-address-btn">
        Add Address
      </button>

      <AddressList 
        addresses={addresses} 
        defaultAddress={defaultAddress}
        onSetDefault={handleSetDefault}
        onDelete={handleDelete}
      />
      
      {/* Modal for adding address */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
            <AddressForm onAddAddress={handleAddAddress} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Address;
