import React, { useState } from "react";
import './Register.css';
import axios from 'axios';
import Swal from 'sweetalert2';

const Registration = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    setError('');

    try {
      await axios.post(`${apiUrl}/api/register`, {
        firstName,
        lastName,
        email,
        password,
      });

      await loginUser(email, password);

      // SweetAlert for successful registration
      Swal.fire({
        title: 'Success!',
        text: 'Registration successful!',
        icon: 'success',
        confirmButtonText: 'OK',
      });

    } catch (err) {
      // SweetAlert for registration failure
      Swal.fire({
        title: 'Error!',
        text: 'Registration failed. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });

    }
  };

  const loginUser = async (email, password) => {
    try {
      const response = await axios.post(`${apiUrl}/api/login`, {
        email,
        password,
      });

      localStorage.setItem('token', response.data.token);
      window.location.href = '/dashboard';
    } catch (err) {
      setError('Login failed. Please check your email or password.');
    }
  };

  return (
    <div className="registration-page">
      <div className="registration-right">
        <div className="form-container">
          <h2>Create an Account</h2>
          <div className="social-login">
            <button className="google-login-btn">
              <img src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png" alt="Google logo" className="google-icon" />
              Sign up with Google
            </button>
          </div>
          <p>Or sign up with your email</p>

          {error && <p className="error-message">{error}</p>}

          <form onSubmit={handleSubmit}>
            <div className="input-field">
              <label>First Name</label>
              <input
                type="text"
                placeholder="Enter your first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="input-field">
              <label>Last Name</label>
              <input
                type="text"
                placeholder="Enter your last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <div className="input-field">
              <label>Email</label>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-field">
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="signup-btn">Register</button>
          </form>
          <p className="login-redirect">
            Already have an account? <a href="/login">Sign in</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Registration;
