import React, { useState, useEffect, useCallback } from 'react';
import './Dashboard.css';
import { useNavigate, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import DashboardHeader from './DashboardHeader';
import Overview from './Overview';
import Settings from './Settings';
import TransactionHistory from './TransactionHistory';
import Address from './MyAddress';
import MyOrders from './MyOrders';

function Dashboard() {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Track the current location

  // Function to toggle sidebar collapse
  const toggleSidebar = () => {
    setSidebarCollapsed(prevState => !prevState);
  };

  // Logout function
  const logout = useCallback(() => {
    localStorage.removeItem('token');
    navigate('/login');
  }, [navigate]);

  // Check if the token is expired
  const isTokenExpired = useCallback(() => {
    const token = localStorage.getItem('token');
    if (!token) return true; // If no token, consider it expired

    try {
      // Assuming the token is a JWT and decoding it
      const [, payload] = token.split('.');
      const decodedPayload = JSON.parse(atob(payload));
      const expiry = decodedPayload.exp * 1000; // Convert to milliseconds
      return Date.now() >= expiry; // Token is expired if current time is past expiry
    } catch (error) {
      return true; // If decoding fails, assume token is invalid
    }
  }, []);

  // Redirect to login if token is expired
  useEffect(() => {
    if (isTokenExpired()) {
      logout();
    }
  }, [isTokenExpired, logout]);

  // Periodically check token validity
  useEffect(() => {
    const interval = setInterval(() => {
      if (isTokenExpired()) {
        logout();
      }
    }, 5 * 60 * 1000); // Check every 5 minutes

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [isTokenExpired, logout]);

  // Scroll to top when the location (route) changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="dashboard-container">
      <Sidebar collapsed={sidebarCollapsed} />
      <div className="main-area">
        <DashboardHeader toggleSidebar={toggleSidebar} />
        <Routes>
          {/* Redirect to Overview if no specific path is provided */}
          <Route path="/" element={<Navigate to="overview" />} />
          <Route path="overview" element={<Overview />} />
          <Route path="settings" element={<Settings />} />
          <Route path="myaddress" element={<Address />} />
          <Route path="orders" element={<MyOrders />} />
          <Route path="reports" element={<TransactionHistory />} />
          {/* Other child routes can be added here */}
        </Routes>
      </div>
    </div>
  );
}

export default Dashboard;
